<template>
  <v-layout row wrap>
    <v-flex xl12 lg12 md12 sm12>
      <ExportData
        :data="exportData"
        :text="$t('Export')"
        color="indigo accent-4"
        :sourceTitle="`Cabinet ${cabinet_Id} - Harmonics Data`"
        :noParsing="true"
      />
    </v-flex>
    <v-flex
      xl4
      lg12
      md12
      sm12
      class="mt-4 pl-2 pr-2"
      style="height: 600px; overflow-y: scroll"
    >
      <v-card>
        <v-layout
          class="tabs-harmonics mt-2 pa-3 center"
          style="border-radius: 16px; position: relative"
        >
          <h2 class="center mt-2">
            {{ $t('Harmonics') + '  -  ' + `${selectedTab}` }}
          </h2>
          <v-icon @click="openEdit" class="editDates">mdi-pencil</v-icon>
        </v-layout>
        <v-layout row wrap v-if="openEditTimes" class="mb-2">
          <v-flex xl3 lg3 md3 sm3 class="center mt-0">
            <div
              class="mt-2"
              style="
                background-color: purple;
                color: white;
                border-radius: 15px;
                margin: 0 10px;
                max-width: 100px;
              "
            >
              <button @click="setData" style="width: 100px; height: 30px">
                {{ $t('Show data') }}
              </button>
            </div>
          </v-flex>
          <v-flex xl3 lg3 md3 sm3 class="center mt-0">
            <v-layout row wrap>
              <v-flex
                class="mt-1"
                xl4
                lg3
                md3
                sm3
                style="font-size: 16px; font-weight: bold"
                >{{ $t('Date') + ':' }}</v-flex
              >
              <v-flex xl8 lg9 md9 class="right mt-1">
                <select
                  v-model="firstDate"
                  style="
                    border: 1px solid black;
                    width: 75px;
                    height: 25px;
                    text-align: center;
                  "
                >
                  <option :value="dates[0]">{{ dates[0] }}</option>
                  <option :value="dates[1]">{{ dates[1] }}</option>
                  <option :value="dates[2]">{{ dates[2] }}</option>
                  <option :value="dates[3]">{{ dates[3] }}</option>
                  <option :value="dates[4]">{{ dates[4] }}</option>
                  <option :value="dates[5]">{{ dates[5] }}</option>
                  <option :value="dates[6]">{{ dates[6] }}</option>
                </select>
              </v-flex>
              <v-flex
                class="mt-1"
                xl4
                lg3
                md3
                sm3
                style="font-size: 16px; font-weight: bold"
                >{{ $t('Hour') + ':' }}</v-flex
              >
              <v-flex xl8 lg9 md9 class="right mt-1">
                <input
                  :value="timeFirst === '' ? firstTime : timeFirst"
                  @change="setFirstTime"
                  type="time"
                  style="
                    border: 1px solid black;
                    width: 75px;
                    text-align: center;
                  "
                  required
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xl3 lg3 md3 sm3 class="center mt-0">
            <v-layout row wrap>
              <v-flex
                class="mt-1"
                xl4
                lg3
                md3
                sm3
                style="font-size: 16px; font-weight: bold"
                >{{ $t('Date') + ':' }}</v-flex
              >
              <v-flex xl8 lg9 md9 class="right mt-1">
                <select
                  v-model="secondDate"
                  style="
                    border: 1px solid black;
                    width: 75px;
                    height: 25px;
                    text-align: center;
                  "
                >
                  <option :value="dates[0]">{{ dates[0] }}</option>
                  <option :value="dates[1]">{{ dates[1] }}</option>
                  <option :value="dates[2]">{{ dates[2] }}</option>
                  <option :value="dates[3]">{{ dates[3] }}</option>
                  <option :value="dates[4]">{{ dates[4] }}</option>
                  <option :value="dates[5]">{{ dates[5] }}</option>
                  <option :value="dates[6]">{{ dates[6] }}</option>
                </select>
              </v-flex>
              <v-flex
                class="mt-1"
                xl4
                lg3
                md3
                sm3
                style="font-size: 16px; font-weight: bold"
                >{{ $t('Hour') + ':' }}</v-flex
              >
              <v-flex xl8 lg9 md9 class="right mt-1">
                <input
                  :value="timeSecond === '' ? secondTime : timeSecond"
                  @change="setSecondTime"
                  type="time"
                  style="
                    border: 1px solid black;
                    width: 75px;
                    text-align: center;
                  "
                  required
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xl3 lg3 md3 sm3 class="center mt-0">
            <v-layout row wrap>
              <v-flex
                class="mt-1"
                xl4
                lg3
                md3
                sm3
                style="font-size: 16px; font-weight: bold"
                >{{ $t('Date') + ':' }}</v-flex
              >
              <v-flex xl8 lg9 md9 class="right mt-1">
                <select
                  v-model="thirdDate"
                  style="
                    border: 1px solid black;
                    width: 75px;
                    height: 25px;
                    text-align: center;
                  "
                >
                  <option :value="dates[0]">{{ dates[0] }}</option>
                  <option :value="dates[1]">{{ dates[1] }}</option>
                  <option :value="dates[2]">{{ dates[2] }}</option>
                  <option :value="dates[3]">{{ dates[3] }}</option>
                  <option :value="dates[4]">{{ dates[4] }}</option>
                  <option :value="dates[5]">{{ dates[5] }}</option>
                  <option :value="dates[6]">{{ dates[6] }}</option>
                </select>
              </v-flex>
              <v-flex
                class="mt-1"
                xl4
                lg3
                md3
                sm3
                style="font-size: 16px; font-weight: bold"
                >{{ $t('Hour') + ':' }}</v-flex
              >
              <v-flex xl8 lg9 md9 class="right mt-1">
                <input
                  :value="timeThird === '' ? thirdTime : timeThird"
                  @change="setThirdTime"
                  type="time"
                  style="
                    border: 1px solid black;
                    width: 75px;
                    text-align: center;
                  "
                  required
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xl3 lg3 md3 sm3>
            <!-- {{ $t('Time') }} -->
          </v-flex>
          <v-flex xl3 lg3 md3 sm3>
            <v-layout row class="center font-14 mt-0 bold">
              {{ new Date(firstSlot[0]).toDateString().substring(4, 10) }}
            </v-layout>
            <v-layout row class="center mt-0 font-14 bold">
              {{
                new Date(firstSlot[0])
                  .toLocaleTimeString('it-IT')
                  .substring(0, 5)
              }}
            </v-layout>
            <v-layout row wrap class="center mt-0">
              <div
                style="width: 16px; height: 16px; background-color: #0000ff"
              />
            </v-layout>
          </v-flex>
          <v-flex xl3 lg3 md3 sm3>
            <v-layout row class="center font-14 mt-0 bold">
              {{ new Date(secondSlot[0]).toDateString().substring(4, 10) }}
            </v-layout>
            <v-layout row class="center mt-0 font-14 bold">
              {{
                new Date(secondSlot[0])
                  .toLocaleTimeString('it-IT')
                  .substring(0, 5)
              }}
            </v-layout>
            <v-layout row wrap class="center mt-0">
              <div
                style="width: 16px; height: 16px; background-color: #a52a2a"
              />
            </v-layout>
          </v-flex>
          <v-flex xl3 lg3 md3 sm3>
            <v-layout row class="center font-14 mt-0 bold">
              {{ new Date(thirdSlot[0]).toDateString().substring(4, 10) }}
            </v-layout>
            <v-layout row class="center mt-0 font-14 bold">
              {{
                new Date(thirdSlot[0])
                  .toLocaleTimeString('it-IT')
                  .substring(0, 5)
              }}
            </v-layout>
            <v-layout row wrap class="center mt-0">
              <div
                style="width: 16px; height: 16px; background-color: #008000"
              />
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout
          row
          wrap
          class="mt-2 mb-2 pb-3"
          v-for="harmonic in harmonics"
          :key="harmonic"
        >
          <v-flex
            xl3
            lg3
            md3
            sm3
            class="bold font-16 center mt-2 mb-2 ml-0 mr-0"
          >
            <v-icon
              @click="hide"
              :value="harmonic"
              class="pl-2 pr-2"
              v-if="showenHarmonics[harmonic]"
              >mdi-eye-off-outline</v-icon
            >
            <v-icon @click="show" :value="harmonic" class="pl-2 pr-2" v-else
              >mdi-eye</v-icon
            >
            {{ harmonic }}
          </v-flex>
          <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-2 mb-2 ml-0 mr-0">
            {{
              firstSlotData[`${selectedTab}_${harmonic}`]
                ? new Number(firstSlotData[`${selectedTab}_${harmonic}`])
                : 0
            }}
          </v-flex>
          <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-2 mb-2 ml-0 mr-0">
            {{
              secondSlotData[`${selectedTab}_${harmonic}`]
                ? new Number(secondSlotData[`${selectedTab}_${harmonic}`])
                : 0
            }}
          </v-flex>
          <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-2 mb-2 ml-0 mr-0">
            {{
              thirdSlotData[`${selectedTab}_${harmonic}`]
                ? new Number(thirdSlotData[`${selectedTab}_${harmonic}`])
                : 0
            }}
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <v-flex xl8 lg12 md12 sm12 class="mt-4">
      <v-card class="">
        <v-layout
          class="tabs-harmonics mt-2 pa-3 center"
          style="border-radius: 16px"
        >
          <div
            @click="setSelectedTab('V1')"
            :class="[
              'tab-harmonics',
              selectedTab === 'V1' ? 'selected-tab-harmonics' : ''
            ]"
          >
            {{ $t('V1') }}
          </div>
          <div
            @click="setSelectedTab('I1')"
            :class="[
              'tab-harmonics',
              selectedTab === 'I1' ? 'selected-tab-harmonics' : ''
            ]"
          >
            {{ $t('I1') }}
          </div>
          <div
            @click="setSelectedTab('V2')"
            :class="[
              'tab-harmonics',
              selectedTab === 'V2' ? 'selected-tab-harmonics' : ''
            ]"
          >
            {{ $t('V2') }}
          </div>
          <div
            @click="setSelectedTab('I2')"
            :class="[
              'tab-harmonics',
              selectedTab === 'I2' ? 'selected-tab-harmonics' : ''
            ]"
          >
            {{ $t('I2') }}
          </div>
          <div
            @click="setSelectedTab('V3')"
            :class="[
              'tab-harmonics',
              selectedTab === 'V3' ? 'selected-tab-harmonics' : ''
            ]"
          >
            {{ $t('V3') }}
          </div>
          <div
            @click="setSelectedTab('I3')"
            :class="[
              'tab-harmonics',
              selectedTab === 'I3' ? 'selected-tab-harmonics' : ''
            ]"
          >
            {{ $t('I3') }}
          </div>
        </v-layout>
        <div id="chart" style="width: 100%">
          <h2 class="center" v-if="selectedTab[0] === 'V'">
            {{ $t('Voltage Harmonics Magnitude') + ' - ' + `${selectedTab}` }}
          </h2>
          <h2 class="center" v-else>
            {{ $t('Current Harmonics Magnitude') + ' - ' + `${selectedTab}` }}
          </h2>
        </div>
        <HarmonicsBar
          v-if="harmonics"
          :harmonicsData="harmonics"
          :barData="bar"
          :showenHarmonics="showenHarmonics"
          :change="change"
        />
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import ExportData from '@/components/ExportData.vue';
import HarmonicsBar from './HarmonicsBar.vue';
import { cloneDeep } from 'lodash';

const months = {
  Jan: 0,
  Feb: 1,
  Mar: 2,
  Apr: 3,
  May: 4,
  Jun: 5,
  Jul: 6,
  Aug: 7,
  Sep: 8,
  Oct: 9,
  Nov: 10,
  Dec: 11
};
export default {
  name: 'HarmonicsTab',
  props: ['streamData'],
  components: { HarmonicsBar, ExportData },
  data() {
    return {
      selectedTab: 'V1',
      harmonics: null,
      firstSlot: null,
      secondSlot: null,
      thirdSlot: null,
      firstSlotData: null,
      secondSlotData: null,
      thirdSlotData: null,
      series: null,
      openEditTimes: false,
      dates: null,
      firstDate: null,
      secondDate: null,
      thirdDate: null,
      firstTime: null,
      secondTime: null,
      thirdTime: null,
      timeFirst: '',
      timeSecond: '',
      timeThird: '',
      lastData: null,
      bar: null,
      exportData: null,
      cabinet_Id: null,
      change: false,
      showenHarmonics: {
        H02: true,
        H03: true,
        H04: true,
        H05: true,
        H06: true,
        H07: true,
        H08: false,
        H09: false,
        H10: false,
        H11: false,
        H12: false,
        H13: false,
        H14: false,
        H15: false,
        H16: false,
        H17: false,
        H18: false,
        H19: false,
        H20: false
      }
    };
  },
  beforeMount() {
    const now = new Date().getTime();
    this.dates = [
      new Date().toDateString().substring(4, 10),
      new Date(now - 86400000).toDateString().substring(4, 10),
      new Date(now - 86400000 * 2).toDateString().substring(4, 10),
      new Date(now - 86400000 * 3).toDateString().substring(4, 10),
      new Date(now - 86400000 * 4).toDateString().substring(4, 10),
      new Date(now - 86400000 * 5).toDateString().substring(4, 10),
      new Date(now - 86400000 * 6).toDateString().substring(4, 10)
    ];
    const device = this.$store.getters['Reports/devicesList_map'].get(this.$route.params.id);
    const deviceId = device['meta.device'].cabinet_id;
    this.cabinet_Id = deviceId;
  },
  mounted() {
    const stream_data = cloneDeep(this.streamData);
    stream_data.forEach(([timestamp, data], index) => {
      let parsed_data = data;
      if (typeof data === 'string') {
        parsed_data = JSON.parse(data.replaceAll('\'', '\"'));
      }
      const new_data = {};
      Object.entries(parsed_data).forEach(([field, value]) => {
        if (field.includes('_H')) {
          new_data[field] = value;
        }
      });
      stream_data[index][1] = new_data;
    });
  
    const tempHarmonics = [];
    for (const key in stream_data[stream_data.length - 1][1]) {
      if (tempHarmonics.indexOf(key.substring(3, 6)) === -1) {
        tempHarmonics.push(key.substring(3, 6));
      }
    }
    this.firstSlot = stream_data[stream_data.length - 3];
    this.secondSlot = stream_data[stream_data.length - 2];
    this.thirdSlot = stream_data[stream_data.length - 1];
    this.firstTime = new Date(this.firstSlot[0])
      .toLocaleTimeString('it-IT')
      .substring(0, 5);
    this.secondTime = new Date(this.secondSlot[0])
      .toLocaleTimeString('it-IT')
      .substring(0, 5);
    this.thirdTime = new Date(this.thirdSlot[0])
      .toLocaleTimeString('it-IT')
      .substring(0, 5);
    this.firstDate = new Date(this.firstSlot[0])
      .toDateString()
      .substring(4, 10);
    this.secondDate = new Date(this.secondSlot[0])
      .toDateString()
      .substring(4, 10);
    this.thirdDate = new Date(this.thirdSlot[0])
      .toDateString()
      .substring(4, 10);
    this.harmonics = tempHarmonics.filter((harmonic) => harmonic !== 'H01');
    this.firstSlotData = this.firstSlot[1];
    this.secondSlotData = this.secondSlot[1];
    this.thirdSlotData = this.thirdSlot[1];
    this.setTableData();
  },
  methods: {
    setSelectedTab(tabName) {
      this.selectedTab = tabName;
      this.setTableData();
      this.change = !this.change;
    },
    openEdit() {
      this.openEditTimes = !this.openEditTimes;
    },
    setFirstTime(e) {
      this.timeFirst = e.target.value;
    },
    setSecondTime(e) {
      this.timeSecond = e.target.value;
    },
    setThirdTime(e) {
      this.timeThird = e.target.value;
    },
    show(e) {
      this.showenHarmonics[`${e.target.getAttribute('value')}`] = true;
      this.change = !this.change;
    },
    hide(e) {
      this.showenHarmonics[`${e.target.getAttribute('value')}`] = false;
      this.change = !this.change;
    },
    setTableData() {
      const tableData = [
        { data: [], color: '#0000FF', name: '' },
        { data: [], color: '#A52A2A', name: '' },
        { data: [], color: '#008000', name: '' }
      ];
      const tempExportData = [];
      for (const j of this.harmonics) {
        tempExportData.push({
          [`${this.cabinet_Id}`]: j,
          [`L1 V_HARMONIC ${new Date(this.firstSlot[0]).toLocaleString()}`]:
            this.firstSlotData[`V1_${j}`],
          [`L1 V_HARMONIC ${new Date(this.secondSlot[0]).toLocaleString()}`]:
            this.secondSlotData[`V1_${j}`],
          [`L1 V_HARMONIC ${new Date(this.thirdSlot[0]).toLocaleString()}`]:
            this.thirdSlotData[`V1_${j}`],
          [`L1 I_HARMONIC ${new Date(this.firstSlot[0]).toLocaleString()}`]:
            this.firstSlotData[`I1_${j}`],
          [`L1 I_HARMONIC ${new Date(this.secondSlot[0]).toLocaleString()}`]:
            this.secondSlotData[`I1_${j}`],
          [`L1 I_HARMONIC ${new Date(this.thirdSlot[0]).toLocaleString()}`]:
            this.thirdSlotData[`I1_${j}`],
          [`L2 V_HARMONIC ${new Date(this.firstSlot[0]).toLocaleString()}`]:
            this.firstSlotData[`V2_${j}`],
          [`L2 V_HARMONIC ${new Date(this.secondSlot[0]).toLocaleString()}`]:
            this.secondSlotData[`V2_${j}`],
          [`L2 V_HARMONIC ${new Date(this.thirdSlot[0]).toLocaleString()}`]:
            this.thirdSlotData[`V2_${j}`],
          [`L2 I_HARMONIC ${new Date(this.firstSlot[0]).toLocaleString()}`]:
            this.firstSlotData[`I2_${j}`],
          [`L2 I_HARMONIC ${new Date(this.secondSlot[0]).toLocaleString()}`]:
            this.secondSlotData[`I2_${j}`],
          [`L2 I_HARMONIC ${new Date(this.thirdSlot[0]).toLocaleString()}`]:
            this.thirdSlotData[`I2_${j}`],
          [`L3 V_HARMONIC ${new Date(this.firstSlot[0]).toLocaleString()}`]:
            this.firstSlotData[`V3_${j}`],
          [`L3 V_HARMONIC ${new Date(this.secondSlot[0]).toLocaleString()}`]:
            this.secondSlotData[`V3_${j}`],
          [`L3 V_HARMONIC ${new Date(this.thirdSlot[0]).toLocaleString()}`]:
            this.thirdSlotData[`V3_${j}`],
          [`L3 I_HARMONIC ${new Date(this.firstSlot[0]).toLocaleString()}`]:
            this.firstSlotData[`I3_${j}`],
          [`L3 I_HARMONIC ${new Date(this.secondSlot[0]).toLocaleString()}`]:
            this.secondSlotData[`I3_${j}`],
          [`L3 I_HARMONIC ${new Date(this.thirdSlot[0]).toLocaleString()}`]:
            this.thirdSlotData[`I3_${j}`]
        });
        tableData[0].data.push(
          this.firstSlotData[`${this.selectedTab + '_' + j}`]
        );
        tableData[1].data.push(
          this.secondSlotData[`${this.selectedTab + '_' + j}`]
        );
        tableData[2].data.push(
          this.thirdSlotData[`${this.selectedTab + '_' + j}`]
        );
      }
      this.exportData = tempExportData;
      this.bar = tableData;
    },
    setData() {
      const year = new Date().getFullYear();
      let newSelection;
      if (this.timeFirst === '') {
        this.timeFirst = this.firstTime;
      }
      let month = months[`${this.firstDate.substring(0, 3)}`];
      let day = parseInt(this.firstDate.substring(4, 6), 10);
      let hour = parseInt(this.timeFirst.substring(0, 2), 10);
      let min = parseInt(this.timeFirst.substring(3, 5), 10);
      if (
        this.firstDate.substring(0, 4) === 'Dec' &&
        this.dates[6].tostring(0, 4) === 'Jan'
      ) {
        newSelection = new Date(year - 1, month, day, hour, min).getTime();
      } else {
        newSelection = new Date(year, month, day, hour, min).getTime();
      }
      if (newSelection < this.streamData[0][0]) {
        this.firstSlot = this.streamData[0];
      } else if (
        newSelection > this.streamData[this.streamData.length - 1][0]
      ) {
        this.firstSlot = this.streamData[this.streamData.length - 1];
      } else {
        for (let index = 0; index < this.streamData.length; index++) {
          if (this.streamData[index][0] >= newSelection) {
            const firstOption = this.streamData[index - 1][0];
            const secondOption = this.streamData[index][0];
            if (secondOption - newSelection < newSelection - firstOption) {
              this.firstSlot = this.streamData[index];
            } else {
              this.firstSlot = this.streamData[index - 1];
            }
            break;
          }
        }
      }
      if (this.timeSecond === '') {
        this.timeSecond = this.secondTime;
      }
      month = months[`${this.secondDate.substring(0, 3)}`];
      day = parseInt(this.secondDate.substring(4, 6), 10);
      hour = parseInt(this.timeSecond.substring(0, 2), 10);
      min = parseInt(this.timeSecond.substring(3, 5), 10);
      if (
        this.secondDate.substring(0, 4) === 'Dec' &&
        this.dates[6].tostring(0, 4) === 'Jan'
      ) {
        newSelection = new Date(year - 1, month, day, hour, min).getTime();
      } else {
        newSelection = new Date(year, month, day, hour, min).getTime();
      }
      if (newSelection < this.streamData[0][0]) {
        this.secondSlot = this.streamData[0];
      } else if (
        newSelection > this.streamData[this.streamData.length - 1][0]
      ) {
        this.secondSlot = this.streamData[this.streamData.length - 1];
      } else {
        for (let index = 0; index < this.streamData.length; index++) {
          if (this.streamData[index][0] >= newSelection) {
            const firstOption = this.streamData[index - 1][0];
            const secondOption = this.streamData[index][0];
            if (secondOption - newSelection < newSelection - firstOption) {
              this.secondSlot = this.streamData[index];
            } else {
              this.secondSlot = this.streamData[index - 1];
            }
            break;
          }
        }
      }
      if (this.timeThird === '') {
        this.timeThird = this.thirdTime;
      }
      month = months[`${this.thirdDate.substring(0, 3)}`];
      day = parseInt(this.thirdDate.substring(4, 6), 10);
      hour = parseInt(this.timeThird.substring(0, 2), 10);
      min = parseInt(this.timeThird.substring(3, 5), 10);
      if (
        this.thirdDate.substring(0, 4) === 'Dec' &&
        this.dates[6].tostring(0, 4) === 'Jan'
      ) {
        newSelection = new Date(year - 1, month, day, hour, min).getTime();
      } else {
        newSelection = new Date(year, month, day, hour, min).getTime();
      }
      if (newSelection < this.streamData[0][0]) {
        this.thirdSlot = this.streamData[0];
      } else if (
        newSelection > this.streamData[this.streamData.length - 1][0]
      ) {
        this.thirdSlot = this.streamData[this.streamData.length - 1];
      } else {
        for (let index = 0; index < this.streamData.length; index++) {
          if (this.streamData[index][0] >= newSelection) {
            const firstOption = this.streamData[index - 1][0];
            const secondOption = this.streamData[index][0];
            if (secondOption - newSelection < newSelection - firstOption) {
              this.thirdSlot = this.streamData[index];
            } else {
              this.thirdSlot = this.streamData[index - 1];
            }
            break;
          }
        }
      }
      this.firstSlotData = this.firstSlot[1];
      this.secondSlotData = this.secondSlot[1];
      this.thirdSlotData = this.thirdSlot[1];
      this.setTableData();
    }
  }
};
</script>
<style scoped>
.tabs-harmonics {
  max-width: 100%;
  height: max-content;
  width: max-content;
  background-color: #fff;
  overflow: hidden;
  white-space: nowrap;
  z-index: 1;
  width: 100%;
}
.tab-harmonics {
  padding: 5px 12px 5px 12px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  display: inline-block;
  height: 100%;
  justify-content: center;
  align-content: center;
  color: #332a8f;
  border: 1px solid #332a8f;
  border-radius: 18px;
}
.selected-tab-harmonics {
  border-radius: 18px;
  background-color: #332a8f;
  color: #fff !important;
}
.bold {
  font-weight: bold;
}
.font-16 {
  font-size: 16px;
}
.font-14 {
  font-size: 14px;
}
.font-18 {
  font-size: 18px;
}
.editDates {
  position: absolute;
  right: 20px;
  cursor: pointer;
}
</style>