<template>
  <div id="chart" style="width: 100%">
    <VueApexCharts
      type="bar"
      height="450"
      width="100%"
      v-bind:options="chartOptions"
      v-bind:series="filteredBarData"
    ></VueApexCharts>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  name: 'HarmonicBar',
  components: { VueApexCharts },
  props: ['harmonicsData', 'barData', 'showenHarmonics', 'change'],
  data() {
    return {
      dates: [],
      chartOptions: {},
      filteredBarData: null,
      filteredHarmonicsData: null
    };
  },
  methods: {
    setData() {
      this.chartOptions = {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '40%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 5,
          colors: ['transparent']
        },
        xaxis: {
          categories: [...this.filteredHarmonicsData]
        }
        // yaxis: {
        //   labels: {
        //     formatter: (val) => {
        //       return val.toFixed(0);
        //     }
        //   },
        //   decimalsInFloat: 0,
        // }
      };
    }
  },
  created() {
    this.filteredHarmonicsData = this.harmonicsData.filter(
      (harmonic) => this.showenHarmonics[harmonic] === true
    );
    const filteredData = JSON.parse(JSON.stringify(this.barData));
    for (let i = this.harmonicsData.length - 1; i > -1; i--) {
      if (this.filteredHarmonicsData.indexOf(this.harmonicsData[i]) === -1) {
        filteredData[0].data.splice(i, 1);
        filteredData[1].data.splice(i, 1);
        filteredData[2].data.splice(i, 1);
      }
    }
    this.filteredBarData = filteredData;
    this.setData();
  },
  watch: {
    change() {
      this.filteredHarmonicsData = this.harmonicsData.filter(
        (harmonic) => this.showenHarmonics[harmonic] === true
      );
      const filteredData = JSON.parse(JSON.stringify(this.barData));
      for (let i = this.harmonicsData.length - 1; i > -1; i--) {
        if (this.filteredHarmonicsData.indexOf(this.harmonicsData[i]) === -1) {
          filteredData[0].data.splice(i, 1);
          filteredData[1].data.splice(i, 1);
          filteredData[2].data.splice(i, 1);
        }
      }
      this.filteredBarData = filteredData;
      this.setData();
    }
  }
};
</script>