










































































































































































































































import TabsInfoMixin from '../../devices/TabsInfoMixin';
import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store';
import ExportData from '@/components/ExportData.vue';
import chartConfig from '../../devices/chart.config';
import VueApexCharts from 'vue-apexcharts';
import API, { Types } from '@/modules/API';
import moment from 'moment';
import momentTZ from 'moment';
import Utils from '@/modules/Utils';
import RadialBar from '@/components/RadialBar.vue';
import BasicTable from '@/components/BasicTable.vue';
import DatePicker from '@/pages/reports/components/DatePicker.vue';

@Component({
  components: {
    ExportData,
    RadialBar,
    VueApexCharts,
    BasicTable,
    DatePicker
  }
})
export default class EnergyTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  @Global.State('lang') lang;
  @Global.State('timezone') projectTimezone;
  @Global.State('readonly_user') readonly_user;

  // @ts-ignore
  @Prop() device;
  @Prop() deviceStreams;
  @Prop() liveReadingsEnergy;
  @Prop() currentTime;
  @Prop() mqtt;
  @Prop() publishMqttMessage;

  phases_live_data = {};
  live_data_modified = '';
  loading = false;
  liveDataColors = ['#5598fb', '#60c6e1', '#7869e2', '#b469df'];
  liveDataInterval = null;
  liveDataHeaders = ['V', 'I', 'kW', 'kvar', 'kVA', 'PF'];
  liveDataFields = ['voltage', 'current', 'kW', 'kvar', 'kVA', 'power_factor'];
  liveDataValues = {};
  chartFields = ['voltage', 'current', 'kW', 'power_factor'];
  line1_series = [];
  line2_series = [];
  line3_series = [];
  live_data_scales = [280, 60, 1, 20];
  liveDataLabels = ['Voltage', 'Current', 'KW', 'Power Factor'];
  series = [
    {
      name: 'energy',
      data: null
    }
  ];
  selectedFields = ['V1'];
  chartOptions = chartConfig.chartOptionsEnergy;
  chartData = {
    V1: [],
    I1: [],
    PF1: [],
    V2: [],
    I2: [],
    PF2: [],
    V3: [],
    I3: [],
    PF3: []
  };
  totalEnergySettings = {
    V1: { unit: 'Volts', color: '#008ffb' },
    I1: { unit: 'Amps', color: '#00e396' },
    PF1: { unit: '', color: '#775dd0' },
    V2: { unit: 'Volts', color: '#feb019' },
    I2: { unit: 'Amps', color: '#ff4560' },
    PF2: { unit: '', color: '#f9a3a4' },
    V3: { unit: 'Volts', color: '#69d2e7' },
    I3: { unit: 'Amps', color: '#546E7A' },
    PF3: { unit: '', color: '#13d8aa' }
  };
  energyData = [];
  totalEnergyLatestHeaders = ['V', 'I', 'PF'];
  totalEnergyFields = ['V1', 'I1', 'PF1', 'V2', 'I2', 'PF2', 'V3', 'I3', 'PF3'];
  totalEnergyHeaders = [
    'Time',
    'V1',
    'I1',
    'PF1',
    'V2',
    'I2',
    'PF2',
    'V3',
    'I3',
    'PF3'
  ];
  totalEnergyValues = {};
  periodSelected = '1days';
  loading_energy = false;
  refresh_live_data = false;
  expand = true;
  start_date = null;
  end_date = null;
  start_time = {
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0
  };

  end_time = {
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 59
  };
  total_energy_relative_time = '';
  live_data_relative_time = '';

  async mounted() {
    this.loading = true;
    const live_data = Utils.getStream('live_data', this.deviceStreams);
    this.phases_live_data = live_data && live_data.value ? Utils.getLiveDataValue(live_data.value) : {};
    this.live_data_modified = await this.getStreamChangeTime(live_data);

    this.updateLiveData();
    await this.loadEnergyData({ since: this.periodSelected });
    this.publishMqttMessage('-1', 'live_data');
    this.total_energy_relative_time = this.totalEnergyValues['timestamp'] 
      ? Utils.translateRelativeTime(Utils.getRelativeTime(this.totalEnergyValues['timestamp']))
      : '';
    this.loading = false;
  }

  setDefaultDates() {
    this.setStartDate(momentTZ().tz(this.projectTimezone).subtract(1, 'days').format('YYYY-MM-DD'));
    this.setEndDate(momentTZ().tz(this.projectTimezone).format('YYYY-MM-DD'));
  }

  get periodsList() {
    return [
      { text: this.$t('Last 24 Hours'), value: '1days' },
      { text: this.$t('Last 48 Hours'), value: '2days' },
      { text: this.$t('Last 7 Days'), value: '7days' },
      { text: this.$t('Last 30 Days'), value: '30days' },
      { text: this.$t('Custom Range'), value: 'custom' }
    ];
  }

  get lines() {
    const original_order = ['1', '2', '3'];
    return this.lang === 'en' ? original_order : original_order.reverse();
  }

  updateSelectedFields(field) {
    if (this.selectedFields.includes(field) && this.selectedFields.length === 1)
      return;

    this.selectedFields.includes(field) && this.selectedFields.length > 1
      ? (this.selectedFields = this.selectedFields.filter((selected) => selected !== field))
      : this.selectedFields.push(field);
  }

  setStartDate(start) {
    this.start_date = start;
  }

  setEndDate(end) {
    this.end_date = end;
  }

  refreshLiveData(){
    this.publishMqttMessage('-1', 'live_data');
    this.refresh_live_data = true;
    setTimeout(() => {
      this.refresh_live_data = false;
    }, 5000);
  }

  @Watch('periodSelected')
  async recreateTable() {
    if (this.periodSelected === 'custom') {
      this.setDefaultDates();
      return;
    }

    this.loading_energy = true;
    await this.loadEnergyData({ since: this.periodSelected });
    this.loading_energy = false;
  }

  async applyCustomRange(){
    this.loading_energy = true;
    await this.loadEnergyData(this.getCustomPeriod());
    this.loading_energy = false;
  }

  @Watch('liveReadingsEnergy')
  updateLiveDataFromMqtt() {
    this.phases_live_data = this.liveReadingsEnergy;
    this.updateLiveData();
    window.dispatchEvent(new Event('resize'));
  }

  clearData() {
    this.energyData = [];
    this.totalEnergyFields.forEach((stream) => (this.chartData[stream] = []));
  }

  getSquareSvg(color) {
    return `data:image/svg+xml,%3c%3fxml version='1.0' encoding='iso-8859-1'%3f%3e %3csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='20' height='20'%3e%3crect x='0' y='0' width='20' height='20' style='fill:${color}%3b' rx='5' ry='5'/%3e%3c/svg%3e`;
  }

  updateLiveData() {
    if (!Object.keys(this.phases_live_data).length) return;
    
    this.lines.forEach((line_number) => {
      this.chartFields.forEach(
        (field, index) =>
          (this[`line${line_number}_series`][index] =
            this.phases_live_data[`${field}${line_number}`])
      );
      const new_values = {};
      this.liveDataFields.forEach(
        (field) =>
          (new_values[field] = this.phases_live_data[`${field}${line_number}`])
      );
      this.liveDataValues[line_number] = new_values;
    });
    this.live_data_relative_time = this.liveDataTime
      ? Utils.translateRelativeTime(Utils.getRelativeTime(this.liveDataTime))
      : '';
  }

  async loadEnergyData(period) {
    this.clearData();
    this.totalEnergyValues = {};
    const energyStream = Utils.getStream('total_energy', this.deviceStreams);
    if (!energyStream) return;

    const energyStreamHistory = await API.get(
      Types.DEVICES,
      `${this.device.id}/streams/${energyStream['id']}/history/`,
      period,
      {}
    );
    if (energyStreamHistory.length) {
      energyStreamHistory.reverse().forEach((energy_history) => this.parseData(energy_history));
      this.totalEnergyValues = this.energyData[0];
    }else {
      this.expand = true;
      const stream_last_value = await Utils.fetchStreamLatestValue(this.device.id, 'total_energy');
      try {
        const last_value = stream_last_value && stream_last_value.lastvalue
          ? JSON.parse(Utils.convertToValidJson(stream_last_value.lastvalue))
          : '';
        this.totalEnergyValues = stream_last_value && last_value
          ? {timestamp: stream_last_value.timestamp, ...last_value }
          : {};
      }catch (e){
        this.totalEnergyValues = {};
      }
    }
    this.updateChartOptions();
  }

  parseData(data) {
    try {
      const fixed = data[1].replaceAll(`'`, '"');
      const parsedElement = JSON.parse(fixed);
      this.updateParsedData(parsedElement, data[0]);
    } catch (error) {
      console.log('%c' + 'error:' + error, 'color: red');
    }
  }

  getCustomPeriod() {
    if (this.start_date > this.end_date) {
      const temp = this.start_date;
      this.start_date = this.end_date;
      this.end_date = temp;
    }
    return {
      from: moment(this.start_date).set(this.start_time).valueOf(),
      to: moment(this.end_date).set(this.end_time).valueOf()
    };
  }

  resetGraphSelection(){
    this.selectedFields = ['V1'];
  }

  updateParsedData(parsedElement, sendDate) {
    const info = {
      timestamp: sendDate,
      time: moment(sendDate).format('DD.MM.YYYY HH:mm'),
      V1: `${parsedElement['V1']}V`,
      I1: `${parsedElement['I1']}A`,
      PF1: parsedElement['L1'],
      V2: `${parsedElement['V2']}V`,
      I2: `${parsedElement['I2']}A`,
      PF2: parsedElement['L2'],
      V3: `${parsedElement['V3']}V`,
      I3: `${parsedElement['I3']}A`,
      PF3: parsedElement['L3']
    };
    this.energyData.push(info);
    this.totalEnergyFields.forEach((stream) =>
      this.chartData[stream].push({ x: sendDate, y: info[stream] })
    );
  }

  get liveDataTime(){
    return this.currentTime || this.live_data_modified || '';
  }

  // checkTotalEnergyOnUpdate(){
  //   if (this.totalEnergyValues.hasOwnProperty('timestamp')) {
  //     return (moment().utcOffset(0).valueOf() - this.totalEnergyValues['timestamp']) <= 60 * 45 * 1000;
  //   }  
  //   return false;
  // }

  @Watch('selectedFields')
  updateChartOptions() {
    this.chartOptions = {
      ...this.chartOptions,
      yaxis: {
        title: {
          text:
            this.selectedFields.length > 1 || !this.selectedFields.length
              ? ''
              : this.totalEnergySettings[this.selectedFields[0]].unit
        },
        tickAmount: 3,
        opposite: this.lang === 'he'
      },
      title: {
        text: this.selectedFields.join(),
        align: 'center'
      },
      colors: Object.keys(this.totalEnergySettings)
        .filter((field) => this.selectedFields.includes(field))
        .map((field) => this.totalEnergySettings[field].color)
    };
    this.series = this.selectedFields.map((field) => ({
      name: field,
      data: this.chartData[field]
    }));
  }

  async getStreamChangeTime(stream) {
    if (!stream) return 'N/A';
    const response = await API.get(Types.DEVICES, `${this.device.id}/streams/${stream.id}/latest/`);

    if (!response['timestamp']) return 'N/A';
    return response.timestamp;
  }

  getSeries(line) {
    return this[`line${line}_series`];
  }

  beforeDestroy() {
    clearInterval(this.liveDataInterval);
  }
}
