



























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import TabsInfoMixin from './TabsInfoMixin';
import { Mixins } from 'vue-mixin-decorator';
import API, { Types } from '@/modules/API';
import MQTT from '@/modules/MQTT';
import { Prop, Watch } from 'vue-property-decorator';
import { Reports, Global, Props } from '@/store';
import PropUtils from '@/modules/PropUtils';
import Utils from '@/modules/Utils';
import FullViewTab from '@/pages/mapView/components/MapTabs/information/hubTabs/FullViewTab.vue';
import IncidentsTab from '@/pages/mapView/components/MapTabs/information/hubTabs/incidents/IncidentsTab.vue';
import DeviceFilesTab from '@/pages/mapView/components/MapTabs/information/hubTabs/DeviceFilesTab.vue';
import EnergyTab from '../information/hubTabs/EnergyTab.vue';
import DeviceDataTab from '../information/hubTabs/DeviceDataTab.vue';
import Connections from '../information/hubTabs/connectionsTab/Connections.vue';
import ControlTab from '../information/hubTabs/ControlTab.vue';
import ConnectionsList from '../information/hubTabs/connections/ConnectionsList.vue';
import AdvancedTab from '../information/hubTabs/AdvancedTab.vue';
import BillingTab from '../information/hubTabs/BillingTab.vue';
import MonitorTab from '../information/hubTabs/MonitorTab.vue';
import VirtualBillingTab from '../information/hubTabs/VirtualBillingTab.vue';
import ConnectionDialog from '@/pages/mapView/components/MapTabs/information/hubTabs/connections/ConnectionDialog.vue';
import moment from 'moment';
import PropEditor from '@/components/PropEditor.vue';
import cloneDeep from 'lodash/cloneDeep';

@Component({
  components: {
    FullViewTab,
    IncidentsTab,
    DeviceFilesTab,
    EnergyTab,
    DeviceDataTab,
    Connections,
    ControlTab,
    ConnectionsList,
    AdvancedTab,
    BillingTab,
    MonitorTab,
    VirtualBillingTab,
    ConnectionDialog,
    PropEditor
  }
})

export default class HubInfoTabs extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  @Global.State('lang') lang;
  @Global.State('mqtt_version_by_class') mqtt_version_by_class;
  @Props.State('list') projectProperties;
  @Reports.State('reportsList') devices;
  @Reports.State('devicesCount') devicesCount;

  // @ts-ignore
  @Prop() selectedTab;
  @Prop() cabinetDevices;
  @Prop() phasesLiveData;
  @Prop() cabinetData;
  @Prop() services_config_status;
  @Prop() updateServiceStatus;
  @Prop() updateMetaDeviceFields;
  @Prop() deviceStreams;
  @Prop() setDeviceStreams;
  @Prop() sys_active_modified;
  @Prop() connection_state;
  @Prop() operation_state_prop;
  @Prop() electricity_price_prop;
  @Prop() incidents_data;
  @Prop() alerts_list;
  @Prop() events_list;

  streamActiveObject = {};
  sysActiveChangeTime = null;
  streamControl = null;
  outputs = {
    c1: null,
    c2: null,
    c3: null,
    c4: null
  };
  streamInputs = {};
  connectionsProp = null;
  temperatureHub = null;
  humidityHub = null;
  mqtt = MQTT.instance;
  metaDeviceData = {};
  liveReadingsNamesMap = {};
  liveReadingsOverview = {};
  liveReadingsEnergy = {};
  liveReadingsAdvanced = [];
  currentTime = null;
  isCompulab = null;
  loadingTabs = true;
  propsList = null;
  teltonica_child_properties = null;
  extentioned_teltonica = false;
  teltonica_child = null;
  teltonica_child_input_streams = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0};
  teltonica_child_output_streams = {1: 0, 2: 0, 3: 0, 4: 0};
  teltonica_child_inputs_property = {};
  teltonica_child_outputs_property = {};
  mqtt_subscribe_topic = '';
  mqtt_subscribe_topic_child = '';
  mqtt_version = 3;
  linked_inputs = [];
  linked_outputs = [];
  output_schedules = { schedule: {}, days_schedule: {}, version: 3, timezone: ''};
  display_new_connections_screen = false;
  connection_dialog = false;
  connection_dialog_data = {
    add: false,
    connection_status: false,
    connection_schedule: {},
    connection_daily_schedule: [],
    current_connection: null
  };
  connections_count = {
    inputs: 12,
    outputs: 4
  };
  inputs_update_status = { timestamp: null, relative_time: ''};
  outputs_update_status = { timestamp: null, relative_time: ''};
  outputs_update_time = null;
  timezone = ''; 

  async mounted() {
    this.timezone = this.$store.state.Global.timezone || this.$store.state.Global.allProjectsTimezone || 'Asia/Tel_Aviv';
    if (this.project && this.project.id !== 'allProjects') this.loadProjectData();
    await this.deviceChanged();
  }

  loadProjectData() {
    const dashboardViewProp = this.projectProperties.find((el) => el.name === 'dashboard.view').value;
    this.isCompulab = dashboardViewProp ? dashboardViewProp['is_compulab'] : null;

    const liveReadingsNamesMap = this.projectProperties.find((el) => el.name === 'dashboard.live_readings_names_map');
    this.liveReadingsNamesMap = liveReadingsNamesMap ? liveReadingsNamesMap.value : {};
  }

  updateOperationState(new_operation_state) {
    this.$emit('updateOperationState', new_operation_state);
  }

  updateTariffData(new_tariff_data) {
    this.$emit('updateTariffData', new_tariff_data);
  }

  initMqtt() {
    this.mqtt.init();
    this.listenToMqtt();
  }

  @Watch('device')
  async deviceChanged() {
    if (!this.device) return;
    try {
      this.loadingTabs = true;
      this.clearData();
      this.fetchStreams();
      await this.fetchProperties();
      if (Utils.hasExtentionedTeltonicaClass(this.device.class_name)) {
        await this.fetchDeviceChildrens();
        if (this.teltonica_child){
          await this.fetchChildStreams();
          await this.fetchChildProperties();
        }
      }
      if (!Utils.hasVirtualCabinetClass(this.device.class_name, this.device['meta.device'])) {
        this.initMqtt();
        this.setConnectionsStatus();
      }
     
      if (this.devices.length === this.devicesCount){
        this.loadingTabs = false;
      }
    }
    catch (error) {
      console.log('ERROR while watch device:', error);
      this.loadingTabs = false;
    }
  }

  setConnectionsStatus(){
    const device_properties = this.teltonica_child ? 'teltonica_child_properties' : 'propsList';
    
    const output_schedules = this[device_properties].results.find((el) => el.name === 'device.schedule');
    const default_value = {schedule: {}, days_schedule: [], version: 3, timezone: this.timezone};
    this.output_schedules = output_schedules && output_schedules.value || default_value;

    let connections = this[device_properties].results.find((el) => el.name === 'device.connections');
    connections = connections && connections.value ? connections.value : {};
    
    this.display_new_connections_screen = !this.extentioned_teltonica && Utils.displayNewConnectionsScreen(this.device['device.version'], connections);

    if (this.display_new_connections_screen) {
      if (!Object.keys(this.output_schedules).length) {
        this.output_schedules = default_value;
      }
      if (Array.isArray(connections)) {
        this.linked_inputs = connections.filter((connection) => connection.type === 'input');
        this.linked_outputs = connections.filter((connection) => connection.type === 'output');
        this.linked_inputs.sort((input1, input2) => input1.gpio_address - input2.gpio_address);
        this.linked_outputs.sort((output1, output2) => output1.gpio_address - output2.gpio_address);
      }else {
        this.linked_inputs = [];
        this.linked_outputs = [];
      }
      
      let connections_count = this[device_properties].results.find((el) => el.name === 'device.satec.dio');
      connections_count = connections_count && connections_count.value ? connections_count.value : null;
      if (connections_count){
        this.connections_count.inputs = connections_count.digital_input.config.num_of_channels;
        this.connections_count.outputs = connections_count.digital_output.config.num_of_channels;
      }
    }else{
      [this.linked_inputs, this.linked_outputs] = this.getLinkedInputsOutputs(connections);
      this.linked_inputs.sort((input1, input2) => input1.input_num - input2.input_num);
      this.linked_outputs.sort((output1, output2) => output1.output_num - output2.output_num);
    }
  }

  @Watch('devices')
  setIsLoading(){
    if (this.devices.length === this.devicesCount){
      this.loadingTabs = false;
    }
  }

  async fetchDeviceChildrens(){
    this.extentioned_teltonica = true;
    const childrens_id = await API.get(Types.PROJECTS, this.project.id + '/devices/' + this.device.id + '/children/');
    // childrens = childrens.filter((children) => Utils.hasExtentionCardClass(children.class_name));
    const child_device_id = childrens_id && childrens_id.length && childrens_id[0] || '';
    if (child_device_id){
      this.teltonica_child = this.devices.find((device) => device.id === child_device_id);
    }
  }

  listenToMqtt() {
    this.mqtt.listen(this.mqtt_subscribe_topic, 
      async (msg, match) => {
        const mqtt_msg_data = this.mqtt.getMqttMsgData(msg, match, this.mqtt_version);

        if (mqtt_msg_data.message_type === 'evt' && mqtt_msg_data.stream_name){
          if (mqtt_msg_data.stream_name === 'live_data') {
            this.parseLiveData(mqtt_msg_data.message);
            this.setCurrentTime();
          }else if (mqtt_msg_data.stream_name === 'control') {
            if (Number(mqtt_msg_data.message) === -1) return;
            this.outputs_update_status.timestamp = mqtt_msg_data.timestamp;
            this.outputs_update_status.relative_time = Utils.translateRelativeTime(Utils.getRelativeTime(mqtt_msg_data.timestamp.timestamp));
            this.streamControl = mqtt_msg_data.message;
            this.updateOutputs(this.streamControl, mqtt_msg_data.format === 'minimal' ? true : false);
          }else if (mqtt_msg_data.stream_name === 'digital_input') {
            if (this.mqttGetMsg(mqtt_msg_data.format, mqtt_msg_data.message) === -1) return;
            this.inputs_update_status.timestamp = mqtt_msg_data.timestamp;
            this.inputs_update_status.relative_time = Utils.translateRelativeTime(Utils.getRelativeTime(mqtt_msg_data.timestamp));
            this.streamInputs = mqtt_msg_data.message;
          }           
        }
      }
    );
    this.publishMqttMessage('-1', 'live_data');
    this.publishMqttMessage('-1', 'control');
    this.publishMqttMessage('-1', 'digital_input');
    if (this.extentioned_teltonica && this.teltonica_child) {
      this.mqtt_subscribe_topic_child = this.mqtt.getSingleDeviceSubscribe(this.teltonica_child.id, this.mqtt_version);
      this.listenToChildMqtt();
    }
  }

  convertToValidJson(value){
    return value.replaceAll(`'`, `"`).replaceAll(`True`, `true`).replaceAll(`False`, `false`);
  }

  listenToChildMqtt(){
    this.mqtt.listen(this.mqtt_subscribe_topic_child,
      async (msg, match) => {
        const mqtt_msg_data = this.mqtt.getMqttMsgData(msg, match, this.mqtt_version);

        if (mqtt_msg_data.message_type === 'evt'){
          if (mqtt_msg_data.stream_name){
            if (mqtt_msg_data.stream_name.includes('input_')){
              const input_num = this.getInputOutputNumber(mqtt_msg_data.stream_name);
              if (input_num && input_num >= 1 && input_num <= 12){
                Vue.set(this.teltonica_child_input_streams, input_num, Number(mqtt_msg_data.message));
              }
            }else if (mqtt_msg_data.stream_name.includes('relay_')){
              const output_num = this.getInputOutputNumber(mqtt_msg_data.stream_name);
              if (output_num && output_num >= 1 && output_num <= 4){
                Vue.set(this.teltonica_child_output_streams, output_num, Number(mqtt_msg_data.message));
              }
            }
          }
        }
      }
    );
  }

  getInputOutputNumber(stream_name){
    const [start, num] = stream_name.split('_');
    return !isNaN(num) && Number(num) || '';
  }

  publishMqttMessage(message, streamName) {
    const mqtt_publish_data = {
      version_num: this.mqtt_version,
      device_id: this.device.id,
      stream_id: this.mqtt_version === 3 ? `${this.device.id}.${streamName}` : streamName,
      message_type: 'cmd',
      format: 'minimal',
      stream_value: message,
      options: {
        retain: false,
        qos: 1
      }
    };

    this.mqtt.publishToSingleDevice(mqtt_publish_data);
  }

  mqttGetMsg(format, message) {
    return format === 'json' ? message : format === 'minimal' ? Number(message) : Number(message['content']);
  }

  setCurrentTime() {
    this.currentTime = moment().utcOffset(0).valueOf();
  }

  parseLiveData(data) {
    const liveReadingsOverview = {};
    const liveReadingsAdvanced = [];
    if (Object.keys(this.liveReadingsNamesMap).length === 0) {
      this.liveReadingsOverview = liveReadingsOverview;
      this.liveReadingsAdvanced = liveReadingsAdvanced;
      return;
    }
    Object.entries(data).forEach((el) => {
      if (this.liveReadingsNamesMap['overview'][el[0]]) {
        liveReadingsOverview[el[0]] = el[1] + ' ' + this.liveReadingsNamesMap['overview'][el[0]].units;
      }
      if (this.liveReadingsNamesMap['advanced'][el[0]]) {
        const infoAdvanced = { name: this.liveReadingsNamesMap['advanced'][el[0]].displayName, value: el[1] + ' ' + this.liveReadingsNamesMap['advanced'][el[0]].units };
        liveReadingsAdvanced.push(infoAdvanced);
      }
    });
    this.liveReadingsEnergy = data;
    this.liveReadingsOverview = liveReadingsOverview;
    this.liveReadingsAdvanced = liveReadingsAdvanced;
    this.$emit('phasesLiveDataChanged', liveReadingsOverview);
  }

  updateOutputs(msg, isNumber) {
    if (isNumber) {
      while (msg > 0) {
        const status = msg % 100 % 10;
        const channel = 'c' + Math.round((msg % 100) / 10);
        msg = Math.round(msg / 100);
        this.outputs = {...this.outputs, [channel]: status};
      }
    }else {
      Object.entries(msg).forEach((el) => {
        const channel = 'c' + (Number(el[0]));
        this.outputs = {...this.outputs, [channel]: el[1]};
      });
    }
  }

  clearData() {
    this.metaDeviceData = {};
    this.currentTime = null;
    this.liveReadingsOverview = {};
    this.liveReadingsAdvanced = [];
    this.temperatureHub = null;
    this.humidityHub = null;
    this.streamControl = null;
    this.streamInputs = {};
    this.outputs = {
      c1: null,
      c2: null,
      c3: null,
      c4: null
    };
    this.extentioned_teltonica = false;
    this.teltonica_child = null;
    this.teltonica_child_properties = null;
    this.teltonica_child_input_streams = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0};
    this.teltonica_child_output_streams = {1: 0, 2: 0, 3: 0, 4: 0};
    this.teltonica_child_inputs_property = {};
    this.teltonica_child_outputs_property = {};
    this.inputs_update_status = {timestamp: null, relative_time: ''};
    this.outputs_update_status = { timestamp: null, relative_time: ''};
  }

  async fetchProperties() {
    const propsList = await API.get(Types.DEVICES, this.device.id + '/properties');
    this.propsList = propsList;

    const metaDeviceProp = propsList.results.find((el) => el.name === 'meta.device');
    const metaDevice = metaDeviceProp ? metaDeviceProp.value : null;
    if (metaDevice && typeof metaDevice === 'object') {
      PropUtils.reorderMetaDevice(metaDevice);
      this.metaDeviceData = metaDevice;
    }
    if (this.mqtt_version_by_class.has(this.device.class_name)) {
      this.mqtt_version = this.mqtt_version_by_class.get(this.device.class_name).mqtt_version;
    }
    this.mqtt_subscribe_topic = this.mqtt.getSingleDeviceSubscribe(this.device.id, this.mqtt_version);
  }

  saveConnection(connection, new_schedule){
    this.updateConnectionsOnServer(connection, new_schedule, true);
    this.setOutputDialog(false, null, false);
  }

  deleteConnection(connection){
    this.updateConnectionsOnServer(connection, null, false);
    this.setOutputDialog(false, null, false);
  }

  updateConnectionsOnServer(new_connection, new_schedule, update_connection){
    let final_outputs_list = cloneDeep(this.linked_outputs);
    let final_inputs_list = cloneDeep(this.linked_inputs);
    
    if (new_connection.type === 'input') {
      final_inputs_list = final_inputs_list.filter((input) => input.gpio_address !== new_connection.gpio_address);
    }else {
      final_outputs_list = final_outputs_list.filter((output) => output.gpio_address !== new_connection.gpio_address);
    }

    if (update_connection) {
      if (new_connection.type === 'input') {
        final_inputs_list.push(new_connection);
        final_inputs_list.sort((input1, input2) => input1.gpio_address - input2.gpio_address);
      }else {
        final_outputs_list.push(new_connection);
        final_outputs_list.sort((output1, output2) => output1.gpio_address - output2.gpio_address);
      }
    }

    const new_connections_list = [...cloneDeep(final_outputs_list), ...cloneDeep(final_inputs_list)];
    
    const connections_property_data = {
      name: 'device.connections',
      objectID: this.device.id,
      objectType: Types.DEVICES
    };

    (this.$refs.propEditor as PropEditor).save(
      new_connections_list,
      connections_property_data,
      () => {
        this.linked_inputs = final_inputs_list;
        this.linked_outputs = final_outputs_list;
        if (new_connection.type === 'output') {
          this.updateSchedulePropertyOnServer(new_connection, new_schedule, update_connection);
        }
      },
      new_connection.type === 'output' 
        ? '' 
        : update_connection 
          ? 'Connection was saved successfully'
          : 'Connection was deleted successfully'
    );
  }

  updateSchedulePropertyOnServer(connection, new_schedule, update_schedule){
    const final_schedules_list = cloneDeep(this.output_schedules);
    
    if (!final_schedules_list.hasOwnProperty('schedule')){
      final_schedules_list.schedule = {};
    }else if (final_schedules_list.schedule.hasOwnProperty(connection.gpio_address)){
      delete final_schedules_list.schedule[connection.gpio_address];
    }
    if (!final_schedules_list.hasOwnProperty('days_schedule')){
      final_schedules_list.days_schedule = {};
    }else if (final_schedules_list.days_schedule.hasOwnProperty(connection.gpio_address)){
      delete final_schedules_list.days_schedule[connection.gpio_address];
    }

    if (update_schedule){
      if (Object.keys(new_schedule.schedule).length && new_schedule.schedule.time_points.length){
        final_schedules_list.schedule[connection.gpio_address] = new_schedule.schedule;
      }
      if (new_schedule.days_schedule.length && new_schedule.days_schedule.some((schedule) => schedule.time_points.length > 0)){
        final_schedules_list.days_schedule[connection.gpio_address] = new_schedule.days_schedule;
      }
    }

    const schedules_property_data = {
      name: 'device.schedule',
      objectID: this.device.id,
      objectType: Types.DEVICES
    };

    (this.$refs.propEditor as PropEditor).save(
      final_schedules_list,
      schedules_property_data,
      () => {
        this.output_schedules = cloneDeep(final_schedules_list);
      },
      update_schedule ? 'Connection was saved successfully' : 'Connection was deleted successfully'
    );
  }

  setOutputDialog(is_open, connection, add){
    this.connection_dialog_data.connection_status = is_open 
      ? add 
        ? true
        : connection.type === 'input'
          ? this.streamInputs[connection.gpio_address]
          : this.streamControl[connection.gpio_address]
      : false;
    this.connection_dialog_data.current_connection = connection;
    this.connection_dialog_data.connection_schedule = {};
    this.connection_dialog_data.connection_daily_schedule = [];

    if (is_open && connection.type === 'output'){
      if (this.output_schedules.hasOwnProperty('days_schedule') && 
        this.output_schedules['days_schedule'].hasOwnProperty(connection.gpio_address)
      ){
        this.connection_dialog_data.connection_daily_schedule = this.output_schedules.days_schedule[connection.gpio_address];
      }
      if (this.output_schedules.hasOwnProperty('schedule') && 
        this.output_schedules['schedule'].hasOwnProperty(connection.gpio_address)
      ){
        this.connection_dialog_data.connection_schedule = this.output_schedules.schedule[connection.gpio_address];
      }
    }
    this.connection_dialog_data.add = add;
    this.connection_dialog = is_open;
  }

  getLinkedInputsOutputs(connections){
    const inputs = [], outputs = [];
    Object.values(connections).forEach((connection) => {
      const desc = connection['desc'];
      const elements = connection['elements'] && Object.values(connection['elements']);
      this.getChildInputOutput(inputs, outputs, elements, desc);
    });
    return [[...inputs], [...outputs]];
  }

  getChildInputOutput(inputs, outputs, elements, connection_desc){
    elements.forEach((element) => {
      if (element.control) {
        outputs.push({
          output_num: element.control,
          element_type: element.element,
          configuration: element.configuration,
          connection_desc,
          onLabel: element.energizedLabel,
          offLabel: element.offLabel
        });
      } else {
        inputs.push({
          input_num: element.input,
          element_type: element.element,
          configuration: element.configuration,
          connection_desc,
          onLabel: element.energizedLabel,
          offLabel: element.element === 'Breaker Monitor' ? element.trippedLabel : element.offLabel,
          gui_line: element.hasOwnProperty('gui_line') ? element.gui_line : 0
        });
      }
      if (!element.hasOwnProperty('children')) return;
      if (element.hasOwnProperty('children') && element['children'].length) this.getChildInputOutput(inputs, outputs, element['children'], connection_desc);
    });
  }

  @Watch('deviceStreams')
  async fetchStreams() {
    this.streamActiveObject = Utils.getStream('sys___active', this.deviceStreams);
    const inputs = Utils.getStream('digital_input', this.deviceStreams);
    const control = Utils.getStream('control', this.deviceStreams);

    let inputs_value = {}, outputs_value = {};

    if (!this.display_new_connections_screen) {
      Array.from({length: 12}, (v, i) => i).forEach((input) => inputs_value[input + 1] = 0);
      Array.from({length: 4}, (v, i) => i).forEach((output) => outputs_value[output + 1] = 0);
    }

    if (inputs && inputs.value) {
      try {
        const digital_input_last_value = await Utils.fetchStreamLatestValue(this.device.id, 'digital_input');
        if (digital_input_last_value && digital_input_last_value.lastvalue) {
          try {
            this.inputs_update_status.timestamp = digital_input_last_value.timestamp;
            this.inputs_update_status.relative_time = Utils.translateRelativeTime(Utils.getRelativeTime(digital_input_last_value.timestamp));
            inputs_value = JSON.parse(Utils.convertToValidJson(digital_input_last_value.lastvalue));
          }catch (e){}
        }
      }catch (e){}
    }
    if (control && control.value){
      try {
        const control_last_value = await Utils.fetchStreamLatestValue(this.device.id, 'control');
        if (control_last_value && control_last_value.lastvalue) {
          try {
            this.outputs_update_status.timestamp = control_last_value.timestamp;
            this.outputs_update_status.relative_time = Utils.translateRelativeTime(Utils.getRelativeTime(control_last_value.timestamp));
            outputs_value = JSON.parse(Utils.convertToValidJson(control_last_value.lastvalue));
          }catch (e){}
        }
      }catch (e){}
      outputs_value = JSON.parse(this.convertToValidJson(control.value));
    }
    this.streamInputs = inputs_value;
    this.streamControl = outputs_value;
  }

  async fetchChildStreams() {
    const response = await API.get(Types.DEVICES, this.teltonica_child.id + '/streams/', {});
    const child_streams = response && response.results;
    if (child_streams){
      child_streams.forEach((stream) => {
        if (stream.name.includes('input_')){
          const input_num = this.getInputOutputNumber(stream.name);

          if (input_num && input_num >= 1 && input_num <= 12){
            this.teltonica_child_input_streams[input_num] = stream.value;
          }
        }else if (stream.name.includes('relay_')){
          const output_num = this.getInputOutputNumber(stream.name);

          if (output_num && output_num >= 1 && output_num <= 4){
            this.teltonica_child_output_streams[output_num] = stream.value;
          }
        }
      });
    }
  }

  async fetchChildProperties() {
    const response = await API.get(Types.DEVICES, this.teltonica_child.id + '/properties');
    this.teltonica_child_properties = response && response.results ? response : [];
    // if (this.teltonica_child_properties){
    //   this.teltonica_child_properties.forEach((property) => {
    //     if (property.name === 'device.controls'){
    //       this.teltonica_child_outputs_property = property.value;
    //     }else if (property.name === 'device.inputs'){
    //       this.teltonica_child_inputs_property = property.value;
    //     }
    //   });
    // }
    // console.log(this.teltonica_child_outputs_property, this.teltonica_child_inputs_property);
  }

  updateMetaDeviceData(new_data){
    this.metaDeviceData = {...new_data};
  }

  beforeDestroy() {
    if (!this.$route.path.includes('cabinet-details')) {
      this.setDeviceStreams([]);
      if (this.mqtt_subscribe_topic) {
        this.mqtt.unlisten(this.mqtt_subscribe_topic);
      }

      if (this.extentioned_teltonica && this.teltonica_child){
        this.mqtt.unlisten(this.mqtt_subscribe_topic_child);
      }
    }
  }
}
