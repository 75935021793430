































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Props, Global } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import Utils from '@/modules/Utils';

@Component({ components: {} })
export default class GeneralStep extends Vue{
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @Global.State('cabinet_connection_alerts') cabinet_connection_alerts;

    @Prop() dialog_data;
    @Prop() current_connection;
    @Prop() output_dialog;
    @Prop() linked_outputs;
    @Prop() linked_inputs;
    @Prop() inputs_options;
    @Prop() outputs_options;
    @Prop() schedule;
    @Prop() daily_schedule;
    @Prop() current_daily_schedule_display;
    @Prop() days_in_week;

    utils = Utils;

    // get output_behavior(){
    //     return [{text: this.$t('Keep Value'), value: 'keep_value'}, {text: this.$t('Momentary'), value: 'momentary'}];
    // }

    mounted(){
        this.$refs.select_gpio_address && this.$refs.select_gpio_address['focus']();
    }

    get gpio_address_options(){
        const selected_inputs = this.linked_inputs.map((input) => input.gpio_address);
        const selected_outputs = this.linked_outputs.map((output) => output.gpio_address);

        return this.output_dialog
            ? this.outputs_options.filter((output_num) => !selected_outputs.includes(output_num))
            : this.inputs_options.filter((input_num) => !selected_inputs.includes(input_num));
    }

    @Watch('schedule')
    @Watch('current_daily_schedule')
    @Watch('current_daily_schedule_display')
    get rest_days(){
        return this.schedule && this.schedule.active 
            ? this.days_in_week.filter((day) => !this.special_days.flat().includes(day[this.lang])).map((day) => day[this.lang])
            : [];
    }

    @Watch('schedule')
    get schedules_list(){
        return this.schedule && this.schedule.active
            ? this.schedule.time_points
            : [];
    }

    @Watch('current_daily_schedule')
    @Watch('current_daily_schedule_display')
    get special_days() {
        return this.current_daily_schedule_display && this.current_daily_schedule_display.length
            ? this.current_daily_schedule_display
                .filter((schedules_set) => schedules_set.active)
                .map((schedules_set) => {
                    return this.days_in_week.filter((day) => schedules_set.days.includes(day.value)).map((day) => day[this.lang]);
                })
            : [];
    }

    @Watch('current_daily_schedule')
    @Watch('current_daily_schedule_display')
    get daily_schedules_list(){
        return this.current_daily_schedule_display && this.current_daily_schedule_display.length
            ? this.current_daily_schedule_display.filter((schedule) => schedule.active)
            : [];
    }

    @Watch('current_connection.gpio_on_state_changed', {deep: true, immediate: true})
    @Watch('current_connection')
    get active_triggers(){
        return this.current_connection.gpio_on_state_changed.filter((trigger) => trigger.enabled);
    }
}

