











































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Props, Global } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

@Component({ 
    components: {}
})
export default class TriggersStep extends Vue{
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @Global.State('cabinet_connection_alerts') cabinet_connection_alerts;

    @Prop() current_connection;
    @Prop() output_dialog;
    @Prop() inputs_options;
    @Prop() current_step;
    @Prop() update_triggers_data;

    triggers_list = [];

    mounted() {
        this.triggers_list = cloneDeep(this.current_connection.gpio_on_state_changed);
    }

    getAvailableTriggerInputs(trigger_index){
        const selected_inputs = this.triggers_list.map((trigger, index) => index === trigger_index ? [] : trigger.inputs).flat();
        return this.inputs_options.filter((input_num) => !selected_inputs.includes(input_num)).map((option) => option);
    }

    @Watch('triggers_list', {deep: true, immediate: true})
    updateApplyButton(){
        let apply_disabled = isEqual(this.triggers_list, this.current_connection.gpio_on_state_changed);
        if (this.output_dialog) {
            apply_disabled = apply_disabled || this.triggers_list.some((trigger) => !trigger.inputs.length);
        }
        this.$emit('setApplyBtnIsDisabled' , apply_disabled);
    }

    addNewTrigger(){
        const new_trigger = this.current_connection === 'input'
            ? {enabled: true, normal_gpio_value: 0, alert_message: ''}
            : {enabled: true, inputs: [], outputs: [], normal_gpio_value: 0, alert_message: ''};
        
        this.triggers_list.push(new_trigger);
    }

    removeTrigger(trigger_index){
        this.triggers_list = this.triggers_list.filter((trigger, index) => index !== trigger_index);
    }

    @Watch('current_step')
    handleStepChanged(){
            if ((!this.output_dialog && this.current_step !== 2 || this.output_dialog && this.current_step !== 3) && this.update_triggers_data){
                this.$emit('updateTriggers', this.triggers_list);
                this.$emit('setApplyBtnIsDisabled' , true);
            }else if (!this.update_triggers_data){
                this.triggers_list = cloneDeep(this.current_connection.gpio_on_state_changed);
            }
    }
    
    get connection_alerts(){
        return Object.entries(this.cabinet_connection_alerts).map(([alert_num, alert_text]) => {
            return ({text: this.$t(alert_text), value: +alert_num});
        });
    }

    get trigger_value_options(){
        return [{text: 'Normally Close', value: 0}, {text: 'Normally Open', value: 1}];
    }
}

