














import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store';

@Component({
  components: {
    VueApexCharts
  }
})
export default class BarChart extends Vue {
  @Prop() series;
  @Prop() chart_title;
  @Prop() colors;
  @Prop() labels;
  @Prop() start_angle;
  @Prop() end_angle;

  @Global.State('lang') lang;

  loading = true;
  chartOptions = {
    chart: {
      height: 250,
      type: 'radialBar',
      defaultLocale: 'en'
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 180,
        hollow: {
          margin: 0,
          size: '40%',
          background: 'transparent',
          image: undefined
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          dropShadow: {
            enabled: true
          },
          name: {
            show: true,
            fontSize: '15px',
            fontFamily: 'Open Sans, Arial, monospace',
            fontWeight: 400,
            offsetY: -20
          },
          value: {
            show: true,
            fontSize: '15px',
            fontFamily: 'Open Sans, Arial, monospace',
            fontWeight: 400,
            offsetY: -12,
            formatter: (val) => {
              return val;
            }
          },
          total: {
            show: true,
            color: '#f6b93f',
            fontSize: '22px',
            fontFamily: 'Open Sans, Arial, monospace',
            label: '',
            formatter: (w) => {
              return '';
            }
          }
        }
      }
    },
    stroke: {
      lineCap: 'round'
    },
    colors: ['#1ab7ea', '#0084ff', '#39539E', '#0077B5'],
    labels: ['V1', 'V2', 'V3', 'V4'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false
          }
        }
      }
    ],
    legend: {
      show: true,
      floating: true,
      fontSize: '13px',
      position: 'bottom',
      offsetX: -160,
      offsetY: 0,
      inverseOrder: false,
      labels: {
        useSeriesColors: true
      },
      markers: {
        width: 0,
        height: 0
      },
      formatter: (seriesName, opts) => {
        return opts.w.globals.series[opts.seriesIndex];
      },
      itemMargin: {
        vertical: 0,
        horizontal: 2
      }
    }
  };

  mounted() {
    this.loading = true;
    if (this.start_angle)
      this.chartOptions.plotOptions.radialBar.startAngle = this.start_angle;
    if (this.end_angle)
      this.chartOptions.plotOptions.radialBar.endAngle = this.end_angle;
    if (this.colors) this.chartOptions.colors = this.colors;
    if (this.labels) this.chartOptions.labels = this.labels;
    if (this.chart_title)
      this.chartOptions.plotOptions.radialBar.dataLabels.total.label = this.chart_title;
    this.loading = false;
  }
}
