







import TabsInfoMixin from '@/pages/mapView/components/MapTabs/devices/TabsInfoMixin';
import Component from 'vue-class-component';
import PowerConsumptionReport from '@/views/PowerConsumptionReport.vue';
import { Mixins } from 'vue-mixin-decorator';

@Component({ 
  components: { PowerConsumptionReport }
})
export default class VirtualBillingTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
    cabinet_id = '';
    show_report = false;

    mounted(){
        const meta_device = this.device['meta.device'];
        this.cabinet_id = meta_device.cabinet_id || '';
        this.show_report = this.cabinet_id ? true : false;
    }
}
