<template>
  <div>
    <v-layout row wrap>
      <v-flex>
        <ExportData
          :data="exportData"
          :text="$t('Export')"
          color="indigo accent-4"
          :sourceTitle="`Cabinet ${cabinet_Id} - Phasor Data - ${new Date(
            lastData[0]
          ).toLocaleString()}`"
          :removeDate="true"
          :noParsing="true"
        />
      </v-flex>
      <v-flex xl12 lg12 md12 class="center">
        <v-card
          v-if="openEditTimes"
          :style="
            select === 'last'
              ? 'width: 200px; height: 130px; border: 1px solid black'
              : 'width: 200px; height: 170px; border: 1px solid black'
          "
        >
          <v-layout
            row
            wrap
            class="mt-1 center"
            style="font-size: 16px; font-weight: bold"
          >
            {{ $t('Pick data') }}
          </v-layout>
          <select
            v-model="select"
            class="center mt-1"
            style="
              width: 80%;
              margin-left: 10%;
              margin-right: 10%;
              border: 1px solid black;
              text-align: center;
            "
          >
            <option value="last">{{ $t('Last') }}</option>
            <option value="pickTime">{{ $t('Pick time') }}</option>
          </select>
          <div
            class="center mt-4"
            style="
              background-color: purple;
              color: white;
              margin: 0 45px;
              border-radius: 15px;
            "
            v-if="select === 'last'"
          >
            <button @click="setLastData" style="width: 100px; height: 30px">
              {{ $t('Show last data') }}
            </button>
          </div>
          <div v-else>
            <v-layout row wrap>
              <v-flex
                class="center mt-1"
                xl6
                lg6
                md6
                style="font-size: 16px; font-weight: bold"
                >{{ $t('Date') }}</v-flex
              >
              <v-flex
                class="center mt-1"
                xl6
                lg6
                md6
                style="font-size: 16px; font-weight: bold"
                >{{ $t('Hour') }}</v-flex
              >
            </v-layout>
            <v-layout row wrap>
              <v-flex xl6 lg6 md6 class="center mt-1">
                <select
                  v-model="pickdate"
                  style="
                    border: 1px solid black;
                    width: 75px;
                    height: 25px;
                    text-align: center;
                  "
                >
                  <option :value="dates[0]">{{ dates[0] }}</option>
                  <option :value="dates[1]">{{ dates[1] }}</option>
                  <option :value="dates[2]">{{ dates[2] }}</option>
                  <option :value="dates[3]">{{ dates[3] }}</option>
                  <option :value="dates[4]">{{ dates[4] }}</option>
                  <option :value="dates[5]">{{ dates[5] }}</option>
                  <option :value="dates[6]">{{ dates[6] }}</option>
                </select>
              </v-flex>
              <v-flex xl6 lg6 md6 class="center mt-1">
                <input
                  @change="setTime"
                  type="time"
                  style="
                    border: 1px solid black;
                    width: 75px;
                    text-align: center;
                  "
                  required
                />
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <div
                class="center mt-2"
                style="
                  background-color: purple;
                  color: white;
                  margin: 0 45px;
                  border-radius: 15px;
                "
              >
                <button
                  @click="setData"
                  :disabled="time.length !== 5"
                  style="width: 100px; height: 30px"
                >
                  {{ $t('Show data') }}
                </button>
              </div>
            </v-layout>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="mt-1 mb-4">
      <v-flex xl12 lg12 md12 style="position: relative">
        <div class="phasorTabHeader center mt-0">
          {{
            $t('Showing data collected at') +
            ' ' +
            new Date(lastData[0]).toLocaleString()
          }}
        </div>
        <v-icon @click="openEdit" class="editDates center">mdi-pencil</v-icon>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xl8 lg8 md12 sm12 class="pr-2 pl-2">
        <v-card>
          <v-layout
            class="tabs-phasor mt-2 pa-3 center"
            style="border-radius: 16px; position: relative"
          >
            <div
              style="
                position: absolute;
                right: 40px;
                top: 30px;
                font-weight: bold;
                font-size: 18px;
              "
            >
              {{ $t('Frequency') + ':   ' + lastData[1]['Frequency'] }}
            </div>
            <div
              @click="setSelectedTab('V1')"
              :class="[
                'tab-phasor',
                selectedTab.indexOf('V1') > -1 ? 'selected-tab-phasor V1' : ''
              ]"
            >
              {{ $t('V1') }}
            </div>
            <div
              @click="setSelectedTab('I1')"
              :class="[
                'tab-phasor',
                selectedTab.indexOf('I1') > -1 ? 'selected-tab-phasor I1' : ''
              ]"
            >
              {{ $t('I1') }}
            </div>
            <div
              @click="setSelectedTab('V2')"
              :class="[
                'tab-phasor',
                selectedTab.indexOf('V2') > -1 ? 'selected-tab-phasor V2' : ''
              ]"
            >
              {{ $t('V2') }}
            </div>
            <div
              @click="setSelectedTab('I2')"
              :class="[
                'tab-phasor',
                selectedTab.indexOf('I2') > -1 ? 'selected-tab-phasor I2' : ''
              ]"
            >
              {{ $t('I2') }}
            </div>
            <div
              @click="setSelectedTab('V3')"
              :class="[
                'tab-phasor',
                selectedTab.indexOf('V3') > -1 ? 'selected-tab-phasor V3' : ''
              ]"
            >
              {{ $t('V3') }}
            </div>
            <div
              @click="setSelectedTab('I3')"
              :class="[
                'tab-phasor',
                selectedTab.indexOf('I3') > -1 ? 'selected-tab-phasor I3' : ''
              ]"
            >
              {{ $t('I3') }}
            </div>
          </v-layout>
          <GaugeChart
            v-if="lastData"
            :chartData="lastData"
            :selectedTab="selectedTab"
          />
        </v-card>
      </v-flex>
      <v-flex xl4 lg4 md12 sm12 class="pr-2 pl-2">
        <v-card class="pa-2">
          <v-layout row>
            <v-flex xl3 lg3 md3 sm3 />
            <v-flex xl3 lg3 md3 sm3 class="bold font-18 center mt-2">
              {{ $t('L1') }}
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="bold font-18 center mt-2">
              {{ $t('L2') }}
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="bold font-18 center mt-2">
              {{ $t('L3') }}
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xl12 lg12 md12 sm12 class="mt-4 center bold font-18">
              {{ $t('Voltage') }}
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xl3 lg3 md3 sm3 class="mt-4 center bold font-16">
              {{ $t('Magnitude') }}
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-4">
              {{ lastData[1]['Phasor_V1_MGT'] }}
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-4">
              {{ lastData[1]['Phasor_V2_MGT'] }}
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-4">
              {{ lastData[1]['Phasor_V3_MGT'] }}
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xl3 lg3 md3 sm3 class="mt-4 center bold font-16">
              {{ $t('Angle') }}
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-4">
              {{ lastData[1]['Phasor_V1_Angle'] }}
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-4">
              {{ lastData[1]['Phasor_V2_Angle'] }}
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-4">
              {{ lastData[1]['Phasor_V3_Angle'] }}
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xl3 lg3 md3 sm3 class="mt-4 center bold font-16">
              {{ $t('Color') }}
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-2">
              <div
                style="width: 16px; height: 16px; border-radius: 3px"
                class="V1 center"
              ></div>
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-2">
              <div
                style="width: 16px; height: 16px; border-radius: 3px"
                class="V2 center"
              ></div>
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-2">
              <div
                style="width: 16px; height: 16px; border-radius: 3px"
                class="V3 center"
              ></div>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xl12 lg12 md12 sm12 class="mt-4 center bold font-18">
              {{ $t('Current') }}
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xl3 lg3 md3 sm3 class="mt-4 center bold font-16">
              {{ $t('Magnitude') }}
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-4">
              {{ lastData[1]['Phasor_I1_MGT'] }}
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-4">
              {{ lastData[1]['Phasor_I2_MGT'] }}
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-4">
              {{ lastData[1]['Phasor_I3_MGT'] }}
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xl3 lg3 md3 sm3 class="mt-4 center bold font-16">
              {{ $t('Angle') }}
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-4">
              {{ lastData[1]['Phasor_I1_Angle'] }}
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-4">
              {{ lastData[1]['Phasor_I2_Angle'] }}
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-4">
              {{ lastData[1]['Phasor_I3_Angle'] }}
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xl3 lg3 md3 sm3 class="mt-4 center bold font-16">
              {{ $t('Color') }}
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-2">
              <div
                style="width: 16px; height: 16px; border-radius: 3px"
                class="I1 center"
              ></div>
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-2">
              <div
                style="width: 16px; height: 16px; border-radius: 3px"
                class="I2 center"
              ></div>
            </v-flex>
            <v-flex xl3 lg3 md3 sm3 class="font-16 center mt-2">
              <div
                style="width: 16px; height: 16px; border-radius: 3px"
                class="I3 center"
              ></div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import GaugeChart from './GaugeChart.vue';
import ExportData from '@/components/ExportData.vue';
import { cloneDeep } from 'lodash';

const months = {
  Jan: 0,
  Feb: 1,
  Mar: 2,
  Apr: 3,
  May: 4,
  Jun: 5,
  Jul: 6,
  Aug: 7,
  Sep: 8,
  Oct: 9,
  Nov: 10,
  Dec: 11
};
export default {
  name: 'PhasorTab',
  props: ['streamData'],
  components: { GaugeChart, ExportData },
  data() {
    return {
      allPhasorData: null,
      lastData: null,
      time: '',
      dates: null,
      select: 'last',
      pickdate: '',
      openEditTimes: false,
      selectedTab: ['V1', 'V2', 'V3', 'I1', 'I2', 'I3'],
      exportData: null,
      cabinet_Id: null,
      freq: null
    };
  },

  beforeMount() {
    const now = new Date().getTime();
    this.dates = [
      new Date().toDateString().substring(4, 10),
      new Date(now - 86400000).toDateString().substring(4, 10),
      new Date(now - 86400000 * 2).toDateString().substring(4, 10),
      new Date(now - 86400000 * 3).toDateString().substring(4, 10),
      new Date(now - 86400000 * 4).toDateString().substring(4, 10),
      new Date(now - 86400000 * 5).toDateString().substring(4, 10),
      new Date(now - 86400000 * 6).toDateString().substring(4, 10)
    ];
    const device = this.$store.getters['Reports/devicesList_map'].get(this.$route.params.id);
    this.cabinet_Id = device['meta.device'].cabinet_id;
  },
  mounted() {
    const stream_data = cloneDeep(this.streamData);
    stream_data.forEach(([timestamp, data], index) => {
      let parsed_data = data;
      if (typeof data === 'string') {
        parsed_data = JSON.parse(data.replaceAll('\'', '\"'));
      }
      const new_data = {};
      Object.entries(parsed_data).forEach(([field, value]) => {
        if (field.includes('Phasor') || field === 'Frequency') {
          new_data[field] = value;
        }
      });
      stream_data[index][1] = new_data;
    });
    this.allPhasorData = stream_data;
    this.lastData = stream_data[stream_data.length - 1];
    this.setExportData();
  },
  methods: {
    setSelectedTab(tabName) {
      if (this.selectedTab.indexOf(tabName) === -1) {
        this.selectedTab.push(tabName);
      } else {
        this.selectedTab = this.selectedTab.filter((tab) => tab !== tabName);
      }
      //   this.setTableData()
    },
    setLastData() {
      this.lastData = this.allPhasorData[this.allPhasorData.length - 1];
      this.setExportData();
    },
    setTime(e) {
      this.time = e.target.value;
    },
    openEdit() {
      this.openEditTimes = !this.openEditTimes;
    },
    setData() {
      const year = new Date().getFullYear();
      const month = months[`${this.pickdate.substring(0, 3)}`];
      const day = parseInt(this.pickdate.substring(4, 6), 10);
      const hour = parseInt(this.time.substring(0, 2), 10);
      const min = parseInt(this.time.substring(3, 5), 10);
      let newSelection;
      if (
        this.pickdate.substring(0, 4) === 'Dec' &&
        this.dates[6].tostring(0, 4) === 'Jan'
      ) {
        newSelection = new Date(year - 1, month, day, hour, min).getTime();
      } else {
        newSelection = new Date(year, month, day, hour, min).getTime();
      }
      if (newSelection < this.allPhasorData[0][0]) {
        this.lastData = this.allPhasorData[0];
      } else if (
        newSelection > this.allPhasorData[this.allPhasorData.length - 1][0]
      ) {
        this.lastData = this.allPhasorData[this.allPhasorData.length - 1];
      } else {
        for (let index = 0; index < this.allPhasorData.length; index++) {
          if (this.allPhasorData[index][0] >= newSelection) {
            const firstOption = this.allPhasorData[index - 1][0];
            const secondOption = this.allPhasorData[index][0];
            if (secondOption - newSelection < newSelection - firstOption) {
              this.lastData = this.allPhasorData[index];
            } else {
              this.lastData = this.allPhasorData[index - 1];
            }
            break;
          }
        }
      }
      this.setExportData();
    },
    setExportData() {
      this.exportData = [
        {
          [`${this.cabinet_Id}`]: 'L1',
          'Volt Magnitude': this.lastData[1]['Phasor_V1_MGT'],
          'Volt Angle': this.lastData[1]['Phasor_V1_Angle'],
          'Current Magnitude': this.lastData[1]['Phasor_I1_MGT'],
          'Current Angle': this.lastData[1]['Phasor_I1_Angle']
        },
        {
          [`${this.cabinet_Id}`]: 'L2',
          'Volt Magnitude': this.lastData[1]['Phasor_V2_MGT'],
          'Volt Angle': this.lastData[1]['Phasor_V2_Angle'],
          'Current Magnitude': this.lastData[1]['Phasor_I2_MGT'],
          'Current Angle': this.lastData[1]['Phasor_I2_Angle']
        },
        {
          [`${this.cabinet_Id}`]: 'L3',
          'Volt Magnitude': this.lastData[1]['Phasor_V3_MGT'],
          'Volt Angle': this.lastData[1]['Phasor_V3_Angle'],
          'Current Magnitude': this.lastData[1]['Phasor_I3_MGT'],
          'Current Angle': this.lastData[1]['Phasor_I3_Angle']
        }
      ];
    }
  }
};
</script>
<style scoped>
.bold {
  font-weight: bold;
}
.font-18 {
  font-size: 18px;
}
.font-16 {
  font-size: 16px;
}
.editDates {
  position: absolute;
  top: -15px;
  cursor: pointer;
}
@media (min-width: 1600px) {
  .editDates {
    right: 32%;
  }
}
@media (min-width: 1350px) {
  .editDates {
    right: 27%;
  }
}
@media (min-width: 800px) {
  .editDates {
    right: 20%;
  }
}
@media (max-width: 500px) {
  .editDates {
    top: -40px;
  }
}
.phasorTabHeader {
  font-size: 22px;
  font-weight: bold;
}
.tab-phasor {
  padding: 5px 12px 5px 12px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  display: inline-block;
  height: 100%;
  justify-content: center;
  align-content: center;
  color: #332a8f;
  border: 1px solid #332a8f;
  border-radius: 18px;
}
.selected-tab-phasor {
  border-radius: 18px;
  color: #fff !important;
}
.I1 {
  background-color: #0b41b7;
}
.I2 {
  background-color: #04580f;
}
.I3 {
  background-color: #b80101;
}
.V1 {
  background-color: #06aca1;
}
.V2 {
  background-color: #56036b;
}
.V3 {
  background-color: #b57103;
}
</style>