









































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';
import Utils from '@/modules/Utils';

@Component({
    components: {
    }
})
export default class ControlTabOutput extends Vue{
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;

    @Prop() output;
    @Prop() output_connected;
    @Prop() output_schedules;

    @Watch('output_schedules', { deep: true, immediate: true})
    get output_next_schedule(){
        return Utils.getTodayScheduleTime(this.output_schedules, this.output.gpio_address);
    }
}

