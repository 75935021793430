<template>
  <div class="blue-rounded-card">
    <div class="center mb-3" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else class="pa-3">
      <v-layout v-if="allData && allData.length" class="tabs-monitor mb-3">
        <div @click="setSelectedTab('demands')" :class="['tab-monitor', selectedTab === 'demands' ? 'selected-tab-monitor' : '']">
          {{ $t('Demands') }}
        </div>
        <div @click="setSelectedTab('harmonics')" :class="['tab-monitor', selectedTab === 'harmonics' ? 'selected-tab-monitor' : '']">
          {{ $t('Harmonics') }}
        </div>
        <div @click="setSelectedTab('phasor')" :class="['tab-monitor', selectedTab === 'phasor' ? 'selected-tab-monitor' : '']">
          {{ $t('Phasor') }}
        </div>
      </v-layout>
      <v-layout v-else justify-center>
        <h3>{{ $t('No Data Available') }}</h3>
      </v-layout>
      <div v-if="selectedTab === 'demands'">
        <DemandsTab :streamData="allData"/>
      </div>
      <div v-else-if="selectedTab === 'harmonics'">
        <HarmonicsTab :streamData="allData" />
      </div>
      <div v-else-if="selectedTab === 'phasor'">
        <PhasorTab :streamData="allData" />
      </div>
    </div>
  </div>
</template>

<script>
import API, { Types } from '@/modules/API';
import DemandsTab from './monitorTabs/DemandsTab.vue';
import HarmonicsTab from './monitorTabs/HarmonicsTab.vue';
import PhasorTab from './monitorTabs/PhasorTab.vue';

export default {
  name: 'MonitorTab',
  components: { DemandsTab, HarmonicsTab, PhasorTab },
  data() {
    return {
      loading: null,
      allData: null,
      selectedTab: 'demands'
    };
  },
  async mounted() {
    this.loading = true;
    await this.getData();
    this.loading = false;
  },
  methods: {
    async getData() {
      try {
        const response = await API.get(
          Types.DEVICES,
          `${
            this.$route.params.id
          }/streams/${`${this.$route.params.id}.pm_data_log`}/history/`,
          { since: '1weeks' },
          {}
        );
        for (const i of response) {
          i[1] = JSON.parse(i[1]);
        }
        this.allData = response;
      } catch (e) {
        console.log(e);
      }
    },
    setSelectedTab(tabName) {
      this.selectedTab = tabName;
    }
  }
};
</script>

<style scoped>

.tabs-monitor {
  max-width: 100%;
  height: 30px;
  width: max-content;
  background-color: #fff;
  overflow: hidden;
  white-space: nowrap;
  z-index: 1;
  border-radius: 16px;
  border: 1px solid #332a8f;
}

.tab-monitor {
  padding: 4px 8px 4px 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  display: inline-block;
  height: 100%;
  justify-content: center;
  align-content: center;
  color: #332a8f;
}

.selected-tab-monitor {
  border-radius: 12px;
  background-color: #332a8f;
  color: #fff !important;
}

</style>