















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store';

@Component({})

export default class BasicTable extends Vue {
    @Prop() headers;
    @Prop() items;

    @Global.State('lang') lang;

    get tableHeaders(){
        return this.headers.map((header) => 
            ({
                text: this.$t(header),
                value: header.toLowerCase() === 'time' ? 'time' : header,
                class: [this.lang === 'en' ? 'text-xs-left' : 'text-xs-right', 'blue-grey lighten-5 font-weight-bold body-2']
            })
        );
    }
}

