


















































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Props, Global } from '@/store';
import { i18n } from '../../../../../../../main';

@Component({
    components: {}
})

export default class BreakerMonitor extends Vue {
    @Prop() device;
    @Prop() streamInputs;
    @Prop() item;
    @Prop() currentConnection;
    @Prop() inputItems;
    @Prop() parentId;
    @Prop() grandParentId;
    @Prop() childItems;

    @Global.State('lang') lang;
    @Global.State('readonly_user') readonly_user;

    actions = [{ text: i18n.t('Do nothing'), value: 'nothing' }, { text: i18n.t('Create event'), value: 'event' }];
    breakerMonitorIcon = require('@/assets/images/cabinet_details_icons/breaker_monitor.png');
    energizedIcon = require('@/assets/images/cabinet_details_icons/energized.png');
    trippedIcon = require('@/assets/images/cabinet_details_icons/tripped.png');
    addChildIcon = require('@/assets/images/cabinet_details_icons/add_child.png');

    inputsStatus = {};

    showEditNameBox = {
        1: {
        actionLabelEnergized: false,
        actionLabelTripped: false
        },
        2: {
        actionLabelEnergized: false,
        actionLabelTripped: false
        },
        3: {
        actionLabelEnergized: false,
        actionLabelTripped: false
        },
        4: {
        actionLabelEnergized: false,
        actionLabelTripped: false
        },
        5: {
        actionLabelEnergized: false,
        actionLabelTripped: false
        },
        6: {
        actionLabelEnergized: false,
        actionLabelTripped: false
        },
        7: {
        actionLabelEnergized: false,
        actionLabelTripped: false
        },
        8: {
        actionLabelEnergized: false,
        actionLabelTripped: false
        },
        9: {
        actionLabelEnergized: false,
        actionLabelTripped: false
        },
        10: {
        actionLabelEnergized: false,
        actionLabelTripped: false
        },
        11: {
        actionLabelEnergized: false,
        actionLabelTripped: false
        },
        12: {
        actionLabelEnergized: false,
        actionLabelTripped: false
        },
        13: {
        actionLabelEnergized: false,
        actionLabelTripped: false
        },
        14: {
        actionLabelEnergized: false,
        actionLabelTripped: false
        }
    };

    async mounted() {
        this.updateInputsStatus();
    }

    @Watch('streamInputs')
    updateInputsStatus() {
        this.inputsStatus = this.streamInputs;
    }

    showEditBox(input, key) {
        this.showEditNameBox[input][key] = true;
    }

    inputChanged(item, event) {
        this.$emit('inputChanged', item, event);
    }

    deleteElement(id, parentId, grandParentId) {
        this.$emit('deleteElement', id, parentId, grandParentId);
    }

    addElement(el, item) {
        this.$emit('addElement', el, item);
    }
    
}

