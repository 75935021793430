

































import Vue from 'vue';
import Component from 'vue-class-component';
import { Props, Global } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class InputBox extends Vue{
    @Global.State('lang') lang;
    @Global.State('readonly_user') readonly_user;

    @Prop() is_connections_tab;
    @Prop() input;
    @Prop() input_connected;

}

