

























































import InputBox from './components/InputBox.vue';
import OutputBox from './components/OutputBox.vue';
import TabsInfoMixin from '@/pages/mapView/components/MapTabs/devices/TabsInfoMixin';
import Component from 'vue-class-component';
import { Props, Global } from '@/store';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import * as sunCalc from 'suncalc';
import moment from 'moment-timezone';

@Component({
    components: {
        InputBox,
        OutputBox
    }
})
export default class ControlTab extends Mixins<TabsInfoMixin>(TabsInfoMixin){
    @Global.State('lang') lang;
    @Global.State('dusk_dawn_data') dusk_dawn_data;

    @Prop() edit_mode;
    @Prop() inputs_status;
    @Prop() outputs_status;
    @Prop() linked_inputs;
    @Prop() linked_outputs;
    @Prop() mqtt_version;
    @Prop() mqtt;
    @Prop() output_schedules;
    @Prop() extentioned_teltonica;
    @Prop() inputs_update_status;
    @Prop() outputs_update_status;

    gui_lines = [];

    mounted(){
        const lines = [...new Set(this.linked_inputs.map((input) => input.gui_line))];
        lines.sort((l1, l2) => l1.toString().localeCompare(l2.toString(), undefined, {numeric: true, sensitivity: 'base'}));
        this.gui_lines = lines;
    }

    changeOutputStatus(output_num, status) {
        const mqtt_publish_data = {
            version_num: this.mqtt_version,
            device_id: this.device.id,
            stream_id: '',
            message_type: 'cmd',
            format: 'minimal',
            stream_value: '',
            options: {
                retain: false,
                qos: 1
            }
        };

        if (this.extentioned_teltonica){
            const value = status ? '1' : '0';
            mqtt_publish_data.stream_id = this.mqtt_version === 3 ? `${this.device.id}.relay_${output_num}` : `relay_${output_num}`;
            mqtt_publish_data.stream_value = value;
        }else {
            const control_stream = {...this.outputs_status};
            control_stream[output_num] = status;
            const msg = { content: control_stream};
            mqtt_publish_data.stream_id = this.mqtt_version === 3 ? `${this.device.id}.control` : 'control';
            mqtt_publish_data.stream_value = JSON.stringify(msg);
        }

        this.mqtt.publishToSingleDevice(mqtt_publish_data);
    }
}
