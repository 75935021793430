

































































































































import InputBox from './InputBox.vue';
import ConnectionTabOutput from './ConnectionTabOutput.vue';
import ControlTabOutput from './ControlTabOutput.vue';
import TabsInfoMixin from '@/pages/mapView/components/MapTabs/devices/TabsInfoMixin';
import Component from 'vue-class-component';
import { Global } from '@/store';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';

@Component({
    components: {
        InputBox,
        ConnectionTabOutput,
        ControlTabOutput
    }
})
export default class ConnectionsList extends Mixins<TabsInfoMixin>(TabsInfoMixin){
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @Global.State('dusk_dawn_data') dusk_dawn_data;
    @Global.State('timezone') projectTimezone;

    @Prop() is_connections_tab;
    @Prop() inputs_status;
    @Prop() outputs_status;
    @Prop() linked_inputs;
    @Prop() linked_outputs;
    @Prop() mqtt_version;
    @Prop() mqtt;
    @Prop() output_schedules;
    @Prop() outputs_update_status;
    @Prop() inputs_update_status;
    @Prop() connections_count;

    setOutputDialog(is_open, connection, add){
        this.$emit('setOutputDialog', is_open, connection, add);
    }

    changeOutputStatus(output_num, status) {
        const mqtt_publish_data = {
            version_num: this.mqtt_version,
            device_id: this.device.id,
            stream_id: '',
            message_type: 'cmd',
            format: 'minimal',
            stream_value: '',
            options: {
                retain: false,
                qos: 1
            }
        };

        const control_stream = {...this.outputs_status};
        control_stream[output_num] = status;
        const msg = { content: control_stream};
        mqtt_publish_data.stream_id = this.mqtt_version === 3 ? `${this.device.id}.control` : 'control';
        mqtt_publish_data.stream_value = JSON.stringify(msg);

        this.mqtt.publishToSingleDevice(mqtt_publish_data);
    }

    addNewOutput(){
        const connection = {
            connection_id: uuidv4(),
            description: '',
            type: 'output',
            output_behavior: 'keep_value',
            gpio_address: '',
            output_on_command: {
                output_momentary_flip_ms: 100
            },
            gpio_on_state_changed: []
        };
        this.$emit('setOutputDialog', true, connection, true);
    }

    addNewInput(){
        const connection = {
            connection_id: uuidv4(),
            description: '',
            type: 'input',
            gpio_address: '',
            label_value_0: 'Off',
            label_value_1: 'On',
            gpio_on_state_changed: []
        };
        this.$emit('setOutputDialog', true, connection, true);
    }

    beforeDestroy() {
        this.setOutputDialog(false, null, false);
    }
}
